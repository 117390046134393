import { Component, Watch } from 'vue-property-decorator';
import Browser from '@/support/browser';
import PlayerApi from '@/support/playerApi';
import ReportPageBase from '@/support/reportPageBase';
import Util from '@/support/utility';
import { PlayColumns } from './playColumns';
import { AllColumns } from './allColumns';
import { InitialSort } from './initialSort';

interface IPlayerUsageDto extends SUR.PlayerUsageDto {
    studioTitle: string;
    study: string;
    streamName: string;
    shareUrl: string;
}

@Component({
    filters: {
        capitalize(str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },

        formatDate(value: string) {
            return Util.formatDate(value);
        }
    }
})
export default class UsageComponent extends ReportPageBase {
    options = {
        allData: Browser.getBoolParam('all', false) || Browser.getBoolParam('allData', false),
        limit: Browser.getIntParam('limit', 0),
        filter: Browser.getParam('filter', null),
    };

    externalUsersOnly = false;

    rawData: IPlayerUsageDto[] = [];
    usageList: IPlayerUsageDto[] = [];

    columns = [];

    playColumns = PlayColumns;
    allColumns = AllColumns;

    $refs = {
        grid: null,
    };

    @Watch('externalUsersOnly')
    async onExternalUsersOnly(val: string, oldVal: string) {
        if (!this.loaded) return;

        this.refresh();
    }

    created() {
        this.reportPageCreated('usageReport', InitialSort, true);

        Debug.setDebugModule('App', this);
    }

    mounted() {
        super.mounted();

        if (this.options.filter)
            this.filterKey = this.options.filter;

        if (this.options.limit)
            this.state.limit = this.options.limit;

        if (this.options.allData)
            this.state.allData = true;

        this.updateColumns();
    }

    beforeDestroy() {
        super.beforeDestroy();
    }

    updateColumns() {
        let columns;

        if (this.state.allData)
            columns = this.allColumns.slice(0, this.allColumns.length);
        else
            columns = this.playColumns.slice(0, this.playColumns.length);

        this.columns.splice(0, this.columns.length);
        columns.forEach(column => {
            this.columns.push(column);
        });

        this.gridColumns = this.columns;
    }

    async getData() {
        return await this.getUsage();
    }

    async getUsage() {
        try {
            let studio = this.studioSelection.selected.studio;

            this.href = null;
            let response = await PlayerApi.usageList(studio, this.state.allData, this.state.limit, this.externalUsersOnly);

            this.usageList.splice(0, this.usageList.length);

            response.forEach(usage => {
                let usageObj = <IPlayerUsageDto>usage;

                this.setUsageMembers(usageObj);

                this.usageList.push(usageObj);
            });

            this.rawData.splice(0, this.rawData.length, ...this.usageList);

            Debug.log('getUsage', this.usageList.length, 'Videos');

            let grid = <any>this.$refs.grid;
            if (grid)
                grid.refresh();

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';

            Util.showError('Failed to get videos list ' + message);
            return null;
        }
    }

    setUsageMembers(usage: IPlayerUsageDto) {
        usage.shareUrl = null;

        if (usage.stream) {
            let parts = usage.stream.split('\\');
            if (parts.length >= 3) {
                usage.studio = parts[0];
                usage.study = parts[1];

                let streamParts = parts.slice(2);
                usage.streamName = streamParts.join('\\');

                // NOTE: shareUrl may not resolve to unique video if share same name
                let params = usage.study + '/' + usage.streamName;
                let url = window.location.origin + '/Studio/' + usage.studio + '?stream=' + encodeURIComponent(params);

                usage.shareUrl = url;
            }
        }

        let studio = this.studios.find(item => item.studio == usage.studio);
        if (studio) {
            usage.studioTitle = studio.title;
        } else {
            usage.studioTitle = usage.studio;
        }
    }

    // base event handlers need to exist in outer class
    onGridDataChanged(count: number) {
        super.onGridDataChanged(count);
    }
}
