export const InitialSort = [
    {
        name: 'eventDateTime',
        ascending: false,
    },
    {
        name: 'studioTitle',
        ascending: true,
    },
    {
        name: 'study',
        ascending: true,
    },
    {
        name: 'shareUrl',
        ascending: true,
    },
];