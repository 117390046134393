export const AllColumns = [
    {
        name: 'action',
    },
    {
        name: 'studioTitle',
        title: 'Studio',
        hide: false,
    },
    {
        name: 'stream',
        title: 'Item',
    },
    {
        name: 'title',
        title: 'Name',
    },
    {
        name: 'userName',
        title: 'Email',
    },
    {
        name: 'workEmail',
        title: 'Work',
    },
    {
        name: 'eventDateTime',
        title: 'Event Time',
        callback: 'formatDate',
    },
];